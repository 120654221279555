<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <FullPageLoadingSpinner
                :show="
                    $wait.is(LoadingFlag.MessagesSave) ||
                    $wait.is(LoadingFlag.UpdateConvoAttributes) ||
                    $wait.is(LoadingFlag.ConvoGetOne)
                "
            />

            <PageHeading :breadcrumbs="breadcrumbs" title="Edit PDL convo" />

            <div class="message-editor">
                <ErrorMessage
                    v-if="modelHasErrors && !$wait.is(LoadingFlag.ConvoGetOne)"
                    ref="errorInfo"
                    size="large"
                >
                    Incomplete fields
                </ErrorMessage>

                <ErrorMessage
                    v-if="
                        convoDetailsView.messagesView.errors &&
                        convoDetailsView.messagesView.errors.length > 0
                    "
                >
                    {{ convoDetailsView.messagesView.errors[0] }}
                </ErrorMessage>

                <ConvoAttributes
                    :data="convoDetailsView.convo"
                    :tags-data="tagsData"
                    @onChange="(data) => setConvoProperty(data)"
                    @tag-input="onTagsInput($event, 'pdl')"
                />

                <Accordion
                    :items="convoDetailsView.messagesView.messageItems"
                    :is-draggable="true"
                    :are-message-items-valid="areMessageItemsValid"
                    :current-channel="channelDetails.channel"
                    :current-convo="convoDetailsView.convo"
                    @openLogicJump="onOpenContentSelector"
                    @headerOnClick="itemHeaderOnClick"
                    @deleteOnClick="deleteOnClick"
                    @onDrop="onDrop"
                    @messageTypeOnSelect="messageTypeOnSelect"
                    @propertyOnChange="setMessageItem"
                    @addItemOnClick="addItemOnClick"
                    @pinItem="onPinItem"
                    @pinAnswer="onPinAnswer"
                    @ignoreQuizQuestion="onIgnoreQuizQuestion"
                    @undoDeletedItemOnClick="undoDeletedItemOnClick"
                    @addAttribute="addAttribute"
                    @removeAttribute="removeAttribute"
                />

                <div class="align-center m-b-2">
                    <TextButton icon="plus-2" @click="addMessageOnClick"> Add message </TextButton>
                </div>
            </div>
        </div>
        <div class="page-layout-aside">
            <div class="page-layout-aside-fixed-container">
                <ConvoPreview
                    class="convo-preview-component"
                    :convo-id="convoDetailsView.convo.id"
                    :fb-page-id="channelDetails.channel.fbPageId"
                    :messages="convoPreviewMessages"
                    :active-message-index="activeMessageIndex"
                    :show-overlay="showConvoPreviewOverlay"
                    :nav-is-opened="navIsOpened"
                    :is-loading="isLoading"
                    :messages-model-has-changes="messagesModelHasChanges"
                    @overlayOnClose="showConvoPreviewOverlay = false"
                />
                <Save
                    :confirm-button-text="confirmButtonText"
                    :nav-is-opened="navIsOpened"
                    :time="new Date(convoDetailsView.convo.updatedAt)"
                    :is-saved="!hasUnsavedChanges"
                    :info="publishedInfo"
                    @cancelButtonOnClick="backButtonClick($event)"
                    @confirmButtonOnClick="nextButtonClick($event)"
                />
            </div>
        </div>

        <Dialog
            show-confirm-button
            :is-dialog-visible="confirmDialogIsOpen"
            :is-loading="
                $wait.is(LoadingFlag.MessagesSave) ||
                $wait.is(LoadingFlag.UpdateConvoAttributes) ||
                $wait.is(LoadingFlag.ConvoGetOne)
            "
            @onClose="confirmDialogIsOpen = false"
            @onClickOutside="confirmDialogIsOpen = false"
            @closeOnEscapeEvent="confirmDialogIsOpen = false"
            @confirmButtonOnClick="dialogSaveButtonClick($event)"
        >
            <template #header>Some changes have been made to the convo.</template>
            <template #body>Do you want to save these changes?</template>
        </Dialog>
        <Dialog
            show-confirm-button
            confirm-button-text="Discard changes"
            close-button-text="Continue Editing"
            :is-dialog-visible="leaveDialogIsOpen"
            @onClose="leaveDialogOnConfirm"
            @onClickOutside="leaveDialogOnConfirm"
            @closeOnEscapeEvent="leaveDialogOnConfirm"
            @confirmButtonOnClick="leaveDialogOnClose"
        >
            <template #header>There are unsaved changes made to the convo.</template>
            <template #body>Continue editing to save progress.</template>
        </Dialog>

        <ContentSelector
            v-if="showContentSelector"
            title="Logic Jump"
            :value="contentItems"
            list-header-text="Jump to..."
            @input="onContentSelectorChange"
            @close="onCloseContentSelector"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import Accordion from '@/components/ui/Accordion';
import Save from '@/components/ui/Save';
import ErrorMessage from '@/components/ui/ErrorMessage';
import ConvoPreview from '@/components/ConvoPreview';
import ConvoAttributes from '@/components/ConvoAttributes';
import ContentSelector from '@/components/ContentSelector/ContentSelector';
import { ConvoMessageItemType } from '@/store/enums/convoMessageItemType.enum.ts';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import convoTypes from '@/json/convoTypes';
import Dialog from '@/components/ui/Dialog';
import LeavingDialogMixin from '@/mixins/leavingDialogMixin';
import TagsMixin from '@/mixins/tagsMixin';
import { LoadingFlag, LinkStyle, ButtonStyle } from '@/store/enums';
import { TagType } from '@/store/enums';
import PageHeading from '@/components/ui/PageHeading';
import TextButton from '@/components/ui/TextButton';

export default {
    name: 'PDLMessages',
    components: {
        Accordion,
        Save,
        FullPageLoadingSpinner,
        ConvoPreview,
        Dialog,
        ErrorMessage,
        ConvoAttributes,
        ContentSelector,
        PageHeading,
        TextButton,
    },
    mixins: [LeavingDialogMixin, TagsMixin],
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            LoadingFlag,
            ButtonStyle,
            LinkStyle,
            channelCode: this.$route.params.channelCode,
            convoId: this.$route.params.convoId,
            showConvoPreviewOverlay: false,
            confirmDialogIsOpen: false,
            breadcrumbs: [],
            showContentSelector: false,
        };
    },
    computed: {
        ...mapGetters('main', {
            isLoading: 'isLoading',
        }),
        ...mapGetters('channel', {
            channelDetails: 'channelDetails',
        }),
        ...mapGetters('convoDetails', {
            activeMessagePath: 'activeMessagePath',
            messageByPath: 'messageByPath',
            convoDetailsView: 'convoDetailsView',
            isConvoValid: 'isConvoValid',
            isMessageViewValid: 'isMessageViewValid',
            areMessageItemsValid: 'areMessageItemsValid',
            areResultItemsValid: 'areResultItemsValid',
            convoModelHasChanges: 'convoModelHasChanges',
            messagesModelHasChanges: 'messagesModelHasChanges',
            convoPreviewMessages: 'convoPreviewMessages',
            availableTags: 'availableTags',
            selectedTags: 'selectedTags',
        }),
        ...mapGetters('logicJump', {
            contentItems: 'items',
        }),
        confirmButtonText() {
            if (this.hasUnsavedChanges) {
                return 'Save changes';
            }
            return 'Finish';
        },
        modelHasErrors() {
            return !this.areMessageItemsValid || !this.isConvoValid || !this.isMessageViewValid;
        },
        convoType() {
            const convoType = convoTypes.find((t) => t.name === this.convoDetailsView.convo.type);
            if (convoType) {
                return convoType.title;
            }
            return 'Convo';
        },
        activeMessageIndex() {
            const activeMessage = this.convoDetailsView.messagesView.messageItems.find(
                (item) => item.isOpen,
            );
            return activeMessage && activeMessage.sort;
        },
        hasUnsavedChanges() {
            return this.messagesModelHasChanges || this.convoModelHasChanges;
        },
        publishedInfo() {
            if (
                this.convoDetailsView.convo.lastPublishedVersion !==
                this.convoDetailsView.convo.version
            ) {
                return 'This version has not been published yet.';
            }

            return undefined;
        },
        tagsData() {
            return {
                selected: this.selectedTags(TagType.pdl),
                available: this.availableTags(TagType.pdl),
                max: 1,
            };
        },
    },
    async created() {
        if (this.convoDetailsView.convo.id != this.$route.params.convoId) {
            await this.loadConvo({ id: this.$route.params.convoId });
        }
        this.setTags({ type: TagType.pdl });
        this.setBreadcrumbs();
    },
    methods: {
        ...mapActions('convoDetails', {
            clearActiveMessagePath: 'clearActiveMessagePath',
            setActiveMessagePath: 'setActiveMessagePath',
            toggleMessageItem: 'toggleMessageItem',
            deleteMessage: 'deleteMessage',
            setMessageType: 'setMessageType',
            setMessageItemsOnReorder: 'setMessageItemsOnReorder',
            setMessageItem: 'setMessageItem',
            validateMessages: 'validateMessages',
            validateConvo: 'validateConvo',
            validateResult: 'validateResult',
            addMessageItem: 'addMessageItem',
            saveConvoMessages: 'saveConvoMessages',
            loadConvo: 'loadConvo',
            setConvo: 'setConvo',
            pinMessageItem: 'pinMessageItem',
            pinMessageAnswer: 'pinMessageAnswer',
            ignoreQuizQuestion: 'ignoreQuizQuestion',
            undoDeletedMessageItem: 'undoDeletedMessageItem',
            resetConvoDetailsState: 'resetConvoDetailsState',
            setConvoProperty: 'setConvoProperty',
            addAttribute: 'addAttribute',
            removeAttribute: 'removeAttribute',
        }),
        ...mapActions('logicJump', {
            clearLogicJump: 'clear',
            getLogicJumpData: 'getData',
        }),
        itemHeaderOnClick(event, path) {
            this.toggleMessageItem(path);
        },
        async deleteOnClick(event, path, parentPath) {
            await this.deleteMessage({ path, parentPath });
            await this.validateMessages();
        },
        async onOpenContentSelector(path) {
            const item = this.messageByPath(path);
            if (!item) {
                return;
            }
            this.setActiveMessagePath(path);
            this.clearLogicJump();
            if (item.logicJump.items && Object.keys(item.logicJump.items).length) {
                await this.getLogicJumpData({
                    targetChannel: item.logicJump.channel ? item.logicJump.channel.code : '',
                    targetConvo: item.logicJump.convo ? item.logicJump.convo.id : 0,
                    targetContentItem: item.logicJump.message ? item.logicJump.message.id : 0,
                });
            }
            this.showContentSelector = true;
        },
        onContentSelectorChange(value) {
            if (!this.activeMessagePath) {
                return;
            }
            const item = this.messageByPath(this.activeMessagePath);
            item.logicJump = { ...value, items: value };
            this.setMessageItem({ path: this.activeMessagePath, item });
        },
        onCloseContentSelector() {
            this.showContentSelector = false;
            this.clearActiveMessagePath();
        },
        onDrop(path, items) {
            this.setMessageItemsOnReorder({ path, items });
        },
        messageTypeOnSelect(event, path, index) {
            this.setMessageType({ path, messageTypeIndex: index });
        },
        backButtonClick: function (event) {
            this.$router.push({ name: 'pdl-list' });
        },
        nextButtonClick: async function (event) {
            await this.validateConvo();
            await this.validateMessages();
            await this.validateResult();

            if (this.modelHasErrors) {
                this.$refs.errorInfo.animate();
                return;
            }
            if (this.hasUnsavedChanges) {
                this.confirmDialogIsOpen = true;
            } else {
                this.$router.push({ name: 'pdl-list' });
            }
        },
        async dialogSaveButtonClick(event) {
            await this.setSelectedTags({ tags: this.selectedTags(TagType.pdl) });
            await this.saveConvoMessages();
            this.confirmDialogIsOpen = false;
        },
        async addMessageOnClick(event) {
            await this.validateMessages();

            this.$nextTick(() => {
                this.addItemOnClick(event, null, ConvoMessageItemType.SelectMessageType);
            });

            if (!this.areMessageItemsValid && this.$refs.errorInfo) {
                this.$refs.errorInfo.animate();
            }
        },
        async addItemOnClick(event, path, type) {
            await this.addMessageItem({ path, type });
            await this.validateMessages();
        },
        onPinItem(path) {
            this.pinMessageItem(path);
        },
        onPinAnswer(path, isPinned) {
            this.pinMessageAnswer({ path, isPinned });
        },
        onIgnoreQuizQuestion(path, isToggled) {
            // TODO: no, this should be passed as a property from quick reply to quick reply answer
            this.ignoreQuizQuestion({ path, isToggled });
            this.$nextTick(this.validateMessages);
        },
        undoDeletedItemOnClick(event, path) {
            this.undoDeletedMessageItem(path);
        },
        setBreadcrumbs() {
            this.breadcrumbs = [
                new Breadcrumb('PDL convos', { name: 'pdl-list' }),
                new Breadcrumb(this.convoDetailsView.convo.name),
            ];
        },
    },
    async beforeRouteLeave(to, from, next) {
        if (!this.$route.meta.group || to.meta.group !== this.$route.meta.group) {
            this.resetConvoDetailsState();
        }

        next();
    },
};
</script>

<style lang="scss" scoped>
.dialog-header p {
    font-weight: 300;
    font-size: 17px;
}

.page-layout-aside-fixed-container {
    @include center(y);
}

.message-editor {
    padding: 0 $message-editor-right-padding 25px $message-editor-left-padding;
}
</style>
